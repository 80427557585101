import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"

const IndexPage = () => (
  <Layout>
    <SEO
      title="Simon Davies / Fullstack Web Developer"
      description="I design and build things for the world wide web..."
    />
    <div className="flex justify-center items-end absolute w-screen max-w-full h-screen max-h-full top-0 left-0 z-10 m-0 p-12 pb-24 md:p-12">
      <div className="flex flex-col md:flex-row justify-between items-center m-0 p-0 w-full">
        <div className="title mb-4 md:mb-0 text-center md:text-left">
          <h1 className="m-0 mb-1 p-0 uppercase text-xl font-thin font-sans text-gray-400 tracking-title">Simon Davies</h1>
          <h2 className="m-0 mb-1 p-0 uppercase font-thin font-sans text-sm text-gray-400 tracking-title leading-snug">Fullstack Web Developer</h2>
        </div>
        <div className="socials-wrapper flex justify-center">
          <a href="https://twitter.com/simon_davies" target="_blank" rel="noopener noreferrer"><svg xmlns="http://www.w3.org/2000/svg" className="transition-colors duration-500 w-6 md:w-4 mx-3 md:mx-1 text-gray-400 hover:text-orange-500" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" stroke-linecap="round" stroke-linejoin="round"><path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path></svg></a>
          <a href="https://www.instagram.com/simondavies01/" target="_blank" rel="noopener noreferrer"><svg xmlns="http://www.w3.org/2000/svg" className="transition-colors duration-500 w-6 md:w-4 mx-3 md:mx-1 text-gray-400 hover:text-orange-500" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" stroke-linecap="round" stroke-linejoin="round"><rect x="2" y="2" width="20" height="20" rx="5" ry="5"></rect><path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path><line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line></svg></a>
          <a href="https://github.com/simondavies" target="_blank" rel="noopener noreferrer"><svg xmlns="http://www.w3.org/2000/svg" className="transition-colors duration-500 w-6 md:w-4 mx-3 md:mx-1 text-gray-400 hover:text-orange-500" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" stroke-linecap="round" stroke-linejoin="round"><path d="M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22"></path></svg></a>
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
